<template>
    <div class="Map AMap" id="Map">
        <div id="viewDiv" class="viewDiv"></div>
        <!-- 底图切换 -->
        <LayerSwitch @switchLayer="switchLayer" />
        <div class="mapLayers">
            <div class="title">固定资产</div>
            <el-tree ref="tree" :data="data" show-checkbox :check-strictly='false' node-key="id" @check="handleChange" @check-change="handleCheckChange" :default-expanded-keys="[10,1001,50, 5003]" :default-checked-keys="checkIds" :props="defaultProps"></el-tree>
        </div>
    </div>
</template>
<script>
import { loadCss, loadModules } from "esri-loader";

import {
    apiDomainCss,
    apiDomainJs,
    RestUrl,
    BgUrl,
    DiMaoUrl,
    YingXiangUrl,
    amapImageUrl,
    amapStreetUrl,
    tdtUrl,
} from "../../map/url";
import { createTintLayer } from "../../map/methods";

// import "../../assets/mapcss/global.less";
import "../../assets/mapcss/mappop.less";
import "../../assets/mapcss/map.less";
import LayerSwitch from "@/components/AMap/LayerSwitch.vue"; //图层选择

export default {
    name: "App",
    components: {
        LayerSwitch,
    },
    data() {
        return {
            data: [],
            defaultProps: {
                children: "children",
                label: "label",
            },
            checkIds: [100101, 100102, 100104, 100105, 100106, 100107, 100108],
            switchLayerIndex: 1,
            count: 1,
            bgLayer: null,
            dmLayer: null,
            yxLayer: null,
            planLayer: null,
            TintLayer: null,
            amapImageLayer: null,
            amapStreetLayer: null,
            tdtLayer: null,
            map: null,
            mapView: null,
            sceneView: null,
            activeView: null,
            legend: null,
            draw: null,
            loading: false, //正在加载中
            token: "", //GIS TOKEN
            measurement: null,
            webMercatorUtils: null,
            Graphic: null,
            QueryTask: null,
            Query: null,
            Point: null,
            watchUtils: null,
            IdentifyParameters: null,
            Extent: null,
            SpatialReference: null,
            Polygon: null,
            find: null,
            FindParameters: null,
            //图层
            zc_gdgs1: null,
            zc_sdgs: null,
            zc_gd: null,
            zc_sd: null,
            zc_xd: null,
            zc_yd: null,
            zc_zygl1: null,
        };
    },
    mounted() {
        this.getData();
        loadCss(apiDomainCss);
        loadModules(
            [
                "esri/Map",
                "esri/views/MapView",
                "esri/views/SceneView",
                "esri/request",
                "esri/layers/WebTileLayer",
                "esri/layers/BaseTileLayer",
                "esri/layers/MapImageLayer",
                "esri/layers/TileLayer",
                "esri/geometry/Point",
                "esri/Graphic",
                "esri/tasks/QueryTask",
                "esri/rest/support/Query",
                "esri/geometry/SpatialReference",
                "esri/geometry/Extent",
                "esri/Color",
                "esri/tasks/IdentifyTask",
                "esri/rest/find",
                "esri/rest/support/FindParameters",
                "esri/rest/support/IdentifyParameters",
                "esri/views/2d/draw/Draw",
                "esri/geometry/Polygon",
                "esri/core/watchUtils",
                "esri/identity/IdentityManager",
                "esri/identity/ServerInfo",
                "esri/widgets/Legend",
                "esri/widgets/Measurement",
                "esri/geometry/support/webMercatorUtils",
            ],
            {
                url: apiDomainJs,
            }
        )
            .then(
                ([
                    Map,
                    MapView,
                    SceneView,
                    request,
                    WebTileLayer,
                    BaseTileLayer,
                    MapImageLayer,
                    TileLayer,
                    Point,
                    Graphic,
                    QueryTask,
                    Query,
                    SpatialReference,
                    Extent,
                    Color,
                    IdentifyTask,
                    find,
                    FindParameters,
                    IdentifyParameters,
                    Draw,
                    Polygon,
                    watchUtils,
                    IdentityManager,
                    ServerInfo,
                    Legend,
                    Measurement,
                    webMercatorUtils,
                ]) => {
                    this.webMercatorUtils = webMercatorUtils;
                    this.Graphic = Graphic;
                    this.QueryTask = QueryTask;
                    this.Query = Query;
                    this.Point = Point;
                    this.watchUtils = watchUtils;
                    this.IdentifyParameters = IdentifyParameters;
                    this.IdentifyTask = IdentifyTask;
                    this.Extent = Extent;
                    this.SpatialReference = SpatialReference;
                    this.Polygon = Polygon;
                    this.find = find;
                    this.FindParameters = FindParameters;

                    //背景图层
                    this.bgLayer = new TileLayer({
                        url: BgUrl,
                        visible: true,
                    });

                    //地貌图层
                    this.dmLayer = new TileLayer({
                        url: DiMaoUrl,
                        visible: false,
                    });

                    //影像图层
                    this.yxLayer = new TileLayer({
                        url: YingXiangUrl,
                        visible: false,
                    });

                    //获取Token
                    // identityManager.tokenValidity = 160; //设置token过期时间720分钟
                    ServerInfo.serverString = RestUrl + "/arcgis/rest/services"; //这里配置ArcGIS Server的REST服务地址
                    ServerInfo.tokenServiceUrl =
                        RestUrl + "/arcgis/tokens/generateToken"; //由于GIS Server和Portal联合了，所以使用Portal的token生成地址
                    // serverInfo.shortLivedTokenValidity = 720;//设置token过期时间720分钟
                    var userInfo = { username: "zzUser", password: "gis_1234" }; //这里填写Portal的用户和密码
                    // console.log("serverInfo", serverInfo);
                    var that = this;
                    IdentityManager.generateToken(ServerInfo, userInfo).then(
                        function (data) {
                            that.token = data.token;
                            window.localStorage.gisToken = data.token;
                            //注册Token，注册之后，在Portal里的所有资源，只要该用户由权限访问，就可以直接使用，之前的所有安全服务请求都将会把token值作为参数发送到服务器端
                            IdentityManager.registerToken({
                                server: RestUrl + "/arcgis/rest/services",
                                token: data.token,
                            });

                            that.zc_gdgs1 = new TileLayer({
                                id: "zc_gdgs1",
                                url: "http://39.100.134.93:6080/arcgis/rest/services/zc_gdgs1/MapServer",
                            });
                            that.zc_sdgs = new TileLayer({
                                id: "zc_sdgs",
                                url: "http://39.100.134.93:6080/arcgis/rest/services/zc_sdgs/MapServer",
                            });
                            that.zc_gd = new TileLayer({
                                id: "zc_gd",
                                url: "http://39.100.134.93:6080/arcgis/rest/services/zc_gd/MapServer",
                            });
                            that.zc_sd = new TileLayer({
                                id: "zc_sd",
                                url: "http://39.100.134.93:6080/arcgis/rest/services/zc_sd/MapServer",
                            });
                            that.zc_xd = new TileLayer({
                                id: "zc_xd",
                                url: "http://39.100.134.93:6080/arcgis/rest/services/zc_xd/MapServer",
                            });
                            that.zc_yd = new TileLayer({
                                id: "zc_yd",
                                url: "http://39.100.134.93:6080/arcgis/rest/services/zc_yd/MapServer",
                            });
                            that.zc_zygl1 = new TileLayer({
                                id: "zc_zygl1",
                                url: "http://39.100.134.93:6080/arcgis/rest/services/zc_zygl1/MapServer",
                            });
                            // console.log(zc_gdgss);
                            that.map.addMany([
                                that.zc_gdgs1,
                                that.zc_sdgs,
                                that.zc_gd,
                                that.zc_sd,
                                that.zc_xd,
                                that.zc_yd,
                                that.zc_zygl1,
                            ]);
                        },
                        function (err) {
                            console.log(err);
                        }
                    );

                    //创建高德地图对象
                    this.TintLayer = createTintLayer(
                        BaseTileLayer,
                        Color,
                        request
                    );

                    //高德影像
                    this.amapImageLayer = new this.TintLayer({
                        urlTemplate: amapImageUrl,
                        tint: new Color("#004FBB"),
                        title: "高德",
                        visible: false,
                    });

                    //高德街区
                    this.amapStreetLayer = new this.TintLayer({
                        urlTemplate: amapStreetUrl,
                        tint: new Color("#004FBB"),
                        title: "高德",
                        visible: false,
                    });

                    //天地图影像
                    this.tdtLayer = new WebTileLayer({
                        urlTemplate: tdtUrl,
                        subDomains: [
                            "t0",
                            "t1",
                            "t2",
                            "t3",
                            "t4",
                            "t5",
                            "t6",
                            "t7",
                        ],
                        copyright: "天地图影像",
                        visible: false,
                    });

                    this.map = new Map({
                        layers: [
                            this.bgLayer,
                            this.dmLayer,
                            this.yxLayer,
                            this.amapImageLayer,
                            this.amapStreetLayer,
                            this.tdtLayer,
                        ],
                    });

                    this.mapView = new MapView({
                        map: this.map,
                        container: "viewDiv",
                        constraints: {
                            rotationEnabled: false, //禁止旋转
                        },
                    });

                    const titleDiv = document.getElementById("titleDiv");
                    this.mapView.ui.add(titleDiv, "top-left");

                    // Create SceneView with similar extent to MapView
                    this.sceneView = new SceneView({
                        map: this.map,
                    });

                    this.activeView = this.mapView;
                    this.measurement = new Measurement();

                    /*矩形*/
                    this.draw = new Draw({
                        view: this.mapView,
                    });

                    //测量初始化
                    this.activeView.set({
                        container: "viewDiv",
                    });
                    this.activeView.ui.add(this.measurement, "bottom-right");
                    this.measurement.view = this.activeView;

                    //点击空白关闭鼠标右键菜单
                    // document
                    //     .getElementById("viewDiv")
                    //     .addEventListener("click", () => {
                    //         this.mouseMenuState("none");
                    //     });

                    //地图缩放监听
                    this.watchUtils.when(
                        this.activeView,
                        "extent",
                        this.animation
                    );
                    this.watchUtils.when(
                        this.activeView,
                        "extent",
                        this.poptemplate
                    );

                    this.mapView.ui.remove("attribution"); //移除底部ESRI logo
                    this.mapView.ui._removeComponents(["zoom"]); //移除默认ui
                }
            )
            .catch((err) => {
                console.error("err", err);
            });
    },
    methods: {
        switchLayer(index) {
            // this.clearScreen();
            this.switchLayerIndex = index;
            this.dmLayer.visible = false;
            this.bgLayer.visible = false;
            this.yxLayer.visible = false;
            this.amapImageLayer.visible = false;
            this.amapStreetLayer.visible = false;
            this.tdtLayer.visible = false;
            switch (index) {
                case 0:
                    this.dmLayer.visible = true;
                    break;
                case 1:
                    this.bgLayer.visible = true;
                    break;
                case 2:
                    this.yxLayer.visible = true;
                    break;
                case 3:
                    this.amapImageLayer.visible = true;
                    this.amapStreetLayer.visible = true;
                    break;
                case 4:
                    this.tdtLayer.visible = true;
                    break;
            }
        },
        setVis(id, checked) {
            if (!id) return false;
            // console.log(id);
            let find = this.map.findLayerById(id);
            if (find) find.visible = checked;
        },

        handleChange(data, checked) {
            // console.log(data,checked);

            this.zc_gdgs1.visible = false;
            this.zc_sdgs.visible = false;
            this.zc_gd.visible = false;
            this.zc_sd.visible = false;
            this.zc_xd.visible = false;
            this.zc_yd.visible = false;
            this.zc_zygl1.visible = false;

            checked.checkedNodes.map((r) => {
                if (r.server) {
                    this.setVis(r.server,true);
                }
            });
        },
        handleCheckChange(data, checked, indeterminate) {
            // console.log(data, checked, indeterminate);
            // if (data.children) {
            //     data.children.map((r) => {
            //         if (r.children) {
            //             r.children.map((rr) => {
            //                 this.setVis(rr.server, checked);
            //             });
            //         } else {
            //             this.setVis(r.server, checked);
            //         }
            //     });
            // } else {
            //     this.setVis(data.server, checked);
            // }
            // this.$refs.tree.setCheckedKeys(this.checkIds);
            // var find = this.map.layers.find(r=>r.id=="zc_gdgs1");
            // find.visible = false
            // console.log();
            // if (data.layerid == undefined) return;
            // if (data.tile == 1) {
            //     if (!checked) {
            //         this.$refs.mapcontainer.removeLayer(data.id);
            //     } else {
            //         this.$refs.mapcontainer.addTileLayer(
            //             data.id,
            //             data.server,
            //             data.zindex
            //         );
            //     }
            //     return;
            // }
            // if (!checked) {
            //     this.$refs.mapcontainer.removeLayer(data.id);
            // } else {
            //     this.$refs.mapcontainer.addLayer(data.id);
            // }
        },
        getData() {
            this.data = [
                {
                    id: 10,
                    label: "公路数据",
                    children: [
                        {
                            id: 1001,
                            label: "公路",
                            children: [
                                {
                                    id: 100101,
                                    label: "国道高速",
                                    layerid: 1,
                                    tile: 1,
                                    server: "zc_gdgs1",
                                    zindex: 999,
                                },
                                {
                                    id: 100102,
                                    label: "省道高速",
                                    layerid: 0,
                                    tile: 1,
                                    server: "zc_sdgs",
                                    zindex: 998,
                                },
                                {
                                    id: 100104,
                                    label: "国道",
                                    layerid: 0,
                                    tile: 1,
                                    server: "zc_gd",
                                    zindex: 997,
                                },
                                {
                                    id: 100105,
                                    label: "省道",
                                    layerid: 0,
                                    tile: 1,
                                    server: "zc_sd",
                                    zindex: 996,
                                },
                                {
                                    id: 100106,
                                    label: "县道",
                                    layerid: 0,
                                    tile: 1,
                                    server: "zc_xd",
                                    zindex: 995,
                                },
                                {
                                    id: 100107,
                                    label: "乡道",
                                    layerid: 0,
                                    tile: 1,
                                    server: "zc_yd",
                                    zindex: 994,
                                },
                                {
                                    id: 100108,
                                    label: "专用公路",
                                    layerid: 0,
                                    tile: 1,
                                    server: "zc_zygl1",
                                    zindex: 993,
                                },
                                { id: 100109, label: "村道" },
                                { id: 100110, label: "桥梁" },
                                { id: 100111, label: "隧道" },
                                { id: 100112, label: "涵洞" },
                            ],
                        },
                        {
                            id: 1002,
                            label: "沿线附属设施",
                            disabled: true,
                            children: [
                                { id: 100201, label: "服务区", disabled: true },
                                { id: 100202, label: "出入口", disabled: true },
                                { id: 100203, label: "收费站", disabled: true },
                                {
                                    id: 100204,
                                    label: "避险车道",
                                    disabled: true,
                                },
                                {
                                    id: 100205,
                                    label: "交通量观测站",
                                    disabled: true,
                                },
                                { id: 100206, label: "标志牌", disabled: true },
                                { id: 100207, label: "加油站", disabled: true },
                                {
                                    id: 100208,
                                    label: "气象监测器",
                                    disabled: true,
                                },
                                {
                                    id: 100209,
                                    label: "能见度仪",
                                    disabled: true,
                                },
                                {
                                    id: 100210,
                                    label: "视频摄像机点",
                                    disabled: true,
                                },
                                {
                                    id: 100211,
                                    label: "交通量调查设备",
                                    disabled: true,
                                },
                                {
                                    id: 100212,
                                    label: "可变情报板",
                                    disabled: true,
                                },
                                { id: 100213, label: "治超站", disabled: true },
                                {
                                    id: 100214,
                                    label: "养护机构",
                                    disabled: true,
                                },
                                { id: 100215, label: "里程碑", disabled: true },
                            ],
                        },
                        {
                            id: 1003,
                            label: "路网风险隐患",
                            disabled: true,
                            children: [
                                {
                                    id: 100301,
                                    label: "危险路段",
                                    disabled: true,
                                },
                                { id: 100302, label: "危桥", disabled: true },
                                {
                                    id: 100303,
                                    label: "危险隧道",
                                    disabled: true,
                                },
                                {
                                    id: 100304,
                                    label: "交通事故频发点",
                                    disabled: true,
                                },
                                {
                                    id: 100305,
                                    label: "交通事故频发路段",
                                    disabled: true,
                                },
                                {
                                    id: 100306,
                                    label: "地质灾害点",
                                    disabled: true,
                                },
                            ],
                        },
                    ],
                },
                {
                    id: 20,
                    label: "道路运输数据",
                    disabled: true,
                    children: [
                        { id: 2001, label: "道路运输管理机构", disabled: true },
                        { id: 2002, label: "重点客货运枢纽", disabled: true },
                        { id: 2003, label: "物流园区及站场", disabled: true },
                        {
                            id: 2004,
                            label: "机动车驾驶培训机构",
                            disabled: true,
                        },
                        {
                            id: 2005,
                            label: "客运班线（跨省高速）",
                            disabled: true,
                        },
                        {
                            id: 2006,
                            label: "货运装载源头企业（重点监管企业）",
                            disabled: true,
                        },
                        {
                            id: 2007,
                            label: "城市客运信息（出租企业、售票班点等）",
                            disabled: true,
                        },
                        { id: 2008, label: "车辆维修检测站", disabled: true },
                    ],
                },
                {
                    id: 30,
                    label: "水路数据",
                    disabled: true,
                    children: [
                        {
                            id: 3001,
                            label: "电子航道图",
                            disabled: true,
                            children: [
                                {
                                    id: 300101,
                                    label: "内河航道",
                                    disabled: true,
                                },
                                { id: 300102, label: "船闸", disabled: true },
                                { id: 300103, label: "航标", disabled: true },
                                {
                                    id: 300104,
                                    label: "水上服务区",
                                    disabled: true,
                                },
                                {
                                    id: 300105,
                                    label: "航道管理单位",
                                    disabled: true,
                                },
                                {
                                    id: 300106,
                                    label: "水上搜救中心",
                                    disabled: true,
                                },
                                {
                                    id: 300107,
                                    label: "海事渡口",
                                    disabled: true,
                                },
                                {
                                    id: 300108,
                                    label: "海事签证点",
                                    disabled: true,
                                },
                                {
                                    id: 300109,
                                    label: "海事管理单位",
                                    disabled: true,
                                },
                                { id: 300110, label: "浮桥", disabled: true },
                                { id: 300111, label: "水库", disabled: true },
                                { id: 300112, label: "港口", disabled: true },
                                { id: 300113, label: "港区", disabled: true },
                                { id: 300114, label: "候泊区", disabled: true },
                                {
                                    id: 300115,
                                    label: "疏港公路",
                                    disabled: true,
                                },
                                { id: 300116, label: "等深线", disabled: true },
                                {
                                    id: 300117,
                                    label: "大型码头泊位",
                                    disabled: true,
                                },
                                {
                                    id: 300118,
                                    label: "小型码头泊位",
                                    disabled: true,
                                },
                                { id: 300119, label: "锚地", disabled: true },
                                {
                                    id: 300120,
                                    label: "仓库堆场",
                                    disabled: true,
                                },
                                {
                                    id: 300121,
                                    label: "港口管理单位",
                                    disabled: true,
                                },
                            ],
                        },
                        {
                            id: 3002,
                            label: "航道库区",
                            disabled: true,
                            children: [
                                {
                                    id: 300201,
                                    label: "水位水情监测设备",
                                    disabled: true,
                                },
                                {
                                    id: 300202,
                                    label: "气象监测设备",
                                    disabled: true,
                                },
                                { id: 300203, label: "摄像机", disabled: true },
                                {
                                    id: 300204,
                                    label: "可变情报板",
                                    disabled: true,
                                },
                            ],
                        },
                        {
                            id: 3003,
                            label: "水网风险隐患",
                            disabled: true,
                            children: [
                                {
                                    id: 300301,
                                    label: "危险航段",
                                    disabled: true,
                                },
                                {
                                    id: 300301,
                                    label: "水上交通事故多发点",
                                    disabled: true,
                                },
                                {
                                    id: 300301,
                                    label: "地质灾害点",
                                    disabled: true,
                                },
                            ],
                        },
                        { id: 3004, label: "水网实景三维地图", disabled: true },
                        { id: 3005, label: "3D建模地图", disabled: true },
                    ],
                },
                {
                    id: 40,
                    label: "动态数据",
                    disabled: true,
                    children: [
                        {
                            id: 4001,
                            label: "两客一危重点运营车辆",
                            disabled: true,
                        },
                        { id: 4002, label: "出租车辆", disabled: true },
                        { id: 4003, label: "路政管理车辆", disabled: true },
                        { id: 4004, label: "运政管理车辆", disabled: true },
                        { id: 4005, label: "养护巡查车辆", disabled: true },
                        {
                            id: 4006,
                            label: "四客一危重点船舶动态数据",
                            disabled: true,
                        },
                        { id: 4007, label: "随手拍", disabled: true },
                    ],
                },
                {
                    id: 50,
                    label: "专题数据",
                    children: [
                        {
                            id: 5001,
                            label: "现状",
                            disabled: true,
                            children: [
                                {
                                    id: 500101,
                                    label: "行政等级专题",
                                    disabled: true,
                                },
                                {
                                    id: 500102,
                                    label: "技术等级专题",
                                    disabled: true,
                                },
                                {
                                    id: 500103,
                                    label: "路面类型站提",
                                    disabled: true,
                                },
                                {
                                    id: 500104,
                                    label: "永久基本农田",
                                    disabled: true,
                                },
                                {
                                    id: 500105,
                                    label: "生态保护红线",
                                    disabled: true,
                                },
                                { id: 500106, label: "图片", disabled: true },
                            ],
                        },
                        {
                            id: 5002,
                            label: "专项",
                            disabled: true,
                            children: [
                                {
                                    id: 500201,
                                    label: "乡镇通三级以上",
                                    disabled: true,
                                },
                                {
                                    id: 500202,
                                    label: "自然村通硬化",
                                    disabled: true,
                                },
                                { id: 500203, label: "灾毁", disabled: true },
                                {
                                    id: 500204,
                                    label: "综合交通网络现状图",
                                    layerid: 13,
                                    title: 0,
                                },
                                {
                                    id: 500205,
                                    label: "综合交通网络规划图（2025)",
                                    layerid: 14,
                                    title: 0,
                                },
                                {
                                    id: 500206,
                                    label: "综合交通运输通道布局图",
                                    layerid: 15,
                                    title: 0,
                                },
                                {
                                    id: 500207,
                                    label: "铁路“十四五”建设项目图",
                                    layerid: 16,
                                    title: 0,
                                },
                                {
                                    id: 500208,
                                    label: "高速公路“十四五”建设项目图",
                                    layerid: 17,
                                    title: 0,
                                },
                                {
                                    id: 500209,
                                    label: "水运“十四五”建设项目图",
                                    layerid: 18,
                                    title: 0,
                                },
                                {
                                    id: 500210,
                                    label: "运输机场“十四五”建设项目图",
                                    layerid: 19,
                                    title: 0,
                                },
                                {
                                    id: 500211,
                                    label: "综合交通客运枢纽“十四五”建设项目图",
                                    layerid: 20,
                                    title: 0,
                                },
                                {
                                    id: 500212,
                                    label: "综合交通货运枢纽“十四五”建设项目图",
                                    layerid: 21,
                                    title: 0,
                                },
                            ],
                        },
                        {
                            id: 5003,
                            label: "规划",
                            children: [
                                {
                                    id: 500301,
                                    label: "高速",
                                    children: [
                                        {
                                            id: 50030101,
                                            label: "三年行动",
                                            layerid: 2,
                                            title: 0,
                                        },
                                        {
                                            id: 50030102,
                                            label: "2023-2025年行动",
                                            layerid: 3,
                                            title: 0,
                                        },
                                        {
                                            id: 50030103,
                                            label: "在建",
                                            layerid: 4,
                                            title: 0,
                                        },
                                        {
                                            id: 50030104,
                                            label: "新开工",
                                            layerid: 5,
                                            title: 0,
                                        },
                                    ],
                                },
                                {
                                    id: 500302,
                                    label: "普通国道",
                                    children: [
                                        {
                                            id: 50030201,
                                            label: "交通部项目库",
                                            layerid: 6,
                                            title: 0,
                                        },
                                        {
                                            id: 50030202,
                                            label: "十四五谋划",
                                            layerid: 7,
                                            title: 0,
                                        },
                                    ],
                                },
                                {
                                    id: 500303,
                                    label: "普通省道",
                                    children: [
                                        {
                                            id: 50030301,
                                            label: "十四五正选",
                                            layerid: 10,
                                            title: 0,
                                        },
                                        {
                                            id: 50030302,
                                            label: "十四五备选",
                                            layerid: 9,
                                            title: 0,
                                        },
                                        {
                                            id: 50030303,
                                            label: "十五五项目",
                                            layerid: 8,
                                            title: 0,
                                        },
                                    ],
                                },
                                { id: 500304, label: "标记图层" },
                                {
                                    id: 500305,
                                    label: "铁科高速",
                                    children: [
                                        {
                                            id: 50030501,
                                            label: "方延段",
                                            layerid: 11,
                                            title: 0,
                                        },
                                        {
                                            id: 50030502,
                                            label: "尚五段",
                                            layerid: 12,
                                            title: 0,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 5004,
                            label: "列养",
                            children: [
                                {
                                    id: 500401,
                                    label: "国道",
                                    children: [
                                        { id: 50040101, label: "列养" },
                                        { id: 50040102, label: "未列养" },
                                        { id: 50040103, label: "砂石" },
                                    ],
                                },
                                {
                                    id: 500402,
                                    label: "省道",
                                    children: [
                                        { id: 50040201, label: "列养" },
                                        { id: 50040202, label: "未列养" },
                                        { id: 50040203, label: "砂石" },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ];
        },
    },
};
</script>
<style scoped lang="less">
.mapLayers {
    overflow: auto;
    width: 260px;
    background: #fff;
    height: 100vh;
    position: absolute;
    padding: 20px 15px;
    top: 0;
    left: 0px;
    bottom: 0;
    z-index: 999;
    box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
        0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12);
    .title {
        border-bottom: 1px solid #ddd;
        height: 46px;
        width: 100%;
        z-index: 1500;
        color: #1d9ef0;
        font-weight: 600;
        line-height: 46px;
        line-height: 46px;
        display: inline-block;
        padding: 0 0 0 20px;
        letter-spacing: 0.1em;
        font-size: 16px;
        text-decoration: none !important;
        box-sizing: border-box;
        margin-bottom: 15px;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            left: 54px;
            top: auto;
            bottom: -2px;
            right: auto;
            height: 3px;
            width: 36px;
            margin-left: -18px;
            border-radius: 2px;
            background-color: #1d9ef0;
        }
    }
}
</style>
